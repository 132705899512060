import React, { createContext, useCallback, useEffect, useImperativeHandle } from "react";
import { useDispatch, useSelector } from "react-redux";
import { URL_PATH, GET_UPDATE } from "@env";
import { CREATE_GAME_PERMISSION, F_PHPSESSID, GAME_ID, USER, USER_GAME_NAME, USER_ID_IN_GAME, USER_ID_IN_GAME_OBSERVER, _CREATOR_ID_, _FILE0_, _F_PHPSESSID_, _LAST_ID_, _MESSAGE_BODY_, _MESSAGE_ID_, _MESSAGE_PARENT_ID_, _SUBJECT_, _TO_LIST_ } from "../config.inc";
import { addNewSystem, setSystemMessages, addReply, markSystem, setChat, setMail, setSocialMedia, setAction, setNews, setDocs, setCostumColors, addLogo, setGamePermission, setUser, setGameInfo, changeLangType, setInGame, setStartGame, end, setToken, setSentMail } from "../redux/actions/index";
import { mapMails } from "./contextFunction";
import { setActionCreator, setEventsCreator, setKpi, setRealPlayers, setReplPlayers } from "../redux/actions/creatorActions";
import { useRef } from "react";
import { LightenDarkenColor } from "../componnets/modules/LightenDarkenColor";
import { getLang } from "../getLang";

export const UpdateContext = createContext();

export default function UpdateProvider(props) {
    const dispatch = useDispatch();
    const gameInfo = useSelector(state => state.gameInfoArr.gameInfoArr);
    const gameInfoPermission = useSelector((state) => state.gameInfoArr.permission);
    const lang = useSelector(state => state.setting.setting.lang);
    const eventsArr = useSelector(state => state.events.events);

    const [events, setEvents] = React.useState([]);

    const isfuncCall = useRef(false);

    const findTo = (ids) => {
        return ids
            .split(",")
            .map(
                (id) =>
                    gameInfo.players.find((player) => player.id === id)?.name_for_game
            )
            .filter((name) => name)
            .join(",");
    };

    const checkIdsInPlayers = (item, gameInfo) => {
        let flag = "";
        if (gameInfo?.players?.length > 0) {
            item.to_list.split(",").forEach((e) => {
                let idExist = false;
                gameInfo.players.forEach((player) => {
                    if (e === player.id || e.length == 0) {
                        idExist = true;
                        return;
                    }
                });
                if (!idExist) {
                    if (item.to_list.indexOf(`${e},`) >= 0) {
                        item.to_list = item.to_list.replace(`${e},`, "");
                    } else {
                        item.to_list = item.to_list.replace(e, "");
                    }
                }
            });
        }
        if (item.to_list.length < 4) {
            flag = "delete";
        } else {
            flag = "edit";
        }
        return {
            newItem: item,
            flag: flag
        }
    }

    const mapActions = (arr, eventsArr) => {
        let kpi = [];
        if (eventsArr?.length > 1) {
            arr.forEach(async (item, i) => {
                let color = eventsArr.find((e) => e.id === item.event_id)?.color;
                item.eventColor = color;
                let from = gameInfo.players.find(
                    (player) => player.id === item.from_id
                )?.name_for_game;
                item.from = from;
                let to = findTo(item.to_list);
                item.to = to;
                if (item.cc_list) {
                    let cc = findTo(item.cc_list);
                    item.cc = cc;
                }
                if (item.bcc_list) {
                    let bcc = findTo(item.bcc_list);
                    item.bcc = bcc;
                }
                //
                let { newItem, flag } = checkIdsInPlayers(item, gameInfo)
                if (flag) {
                    // await addAction(newItem, arr.length - 1, flag === "edit" ? false : true);
                }
                //
                if (item.kpi === "1" || item.kpi && item.kpi !== "0") {
                    kpi.push(item);
                }
            });
        }
        return {
            kpi: kpi,
            action: arr,
        };
    };

    const mapPlayers = (playersArr) => {
        let realP = [];
        let repP = [];
        if (
            playersArr &&
            Array.isArray(playersArr.players) &&
            playersArr.players.length > 0
        ) {
            playersArr.players.forEach((player, i) => {
                if (
                    player.is_represented === "1"
                ) {
                    repP.push(player);
                } else if (player.permission_for_game === "15" && player.id !== sessionStorage.getItem(USER_ID_IN_GAME)) {
                    realP.push(player);
                }
            });
        }
        return {
            real: realP,
            rep: repP,
        };
    };

    const setColors = (colorsString) => {
        try {
            let colors = colorsString?.game_colors.split(",");
            dispatch(setCostumColors({
                color1: `#${colors[0]}`,
                colorShade1: LightenDarkenColor(`#${colors[0]}`, 0),
                colorCurrent1: `#${colors[0]}`,
                color2: `#${colors[1]}`,
                colorShade2: LightenDarkenColor(`#${colors[1]}`, 0),
                colorCurrent2: `#${colors[1]}`,
                color3: `#${colors[2]}`,
                colorShade3: LightenDarkenColor(`#${colors[2]}`, 0),
                colorCurrent3: `#${colors[2]}`,
            }, true));
        } catch (e) {
            console.log(e);
        }
    }

    const checkIfIsObserver = (game_info) => {
        if (sessionStorage.getItem(CREATE_GAME_PERMISSION) !== "1") {
            let observer = game_info?.players?.find(
                (player) =>
                    player.id === sessionStorage.getItem(USER_ID_IN_GAME)
            )?.observer_id;
            if (observer) {
                sessionStorage.setItem(USER_ID_IN_GAME_OBSERVER, observer);
            }
        }
    }

    const checkStartGame = (res) => {
        if (
            !res.game_info?.start_time &&
            sessionStorage.getItem(CREATE_GAME_PERMISSION) !== "1" &&
            res.game_info?.players?.find(player => player.id === sessionStorage.getItem(USER_ID_IN_GAME))?.permission_for_game !== "1"
        ) {
            dispatch(setInGame());
            dispatch(setStartGame(false));
            return true;
        } else {
            if (res.game_info.end_time && (res.game_info?.players?.find(player => player.id === sessionStorage.getItem(USER_ID_IN_GAME))?.permission_for_game !== "1" && sessionStorage.getItem("create_game_permission") !== "1")) {
                // sessionStorage.removeItem(GAME_ID);
                dispatch(end());
                dispatch(setStartGame(false));
                return false
            }
            dispatch(setToken(true));
            dispatch(setStartGame(true));
            return true;
        }
    };

    const getUpdateData = (eventsArr) => {
        if (!isfuncCall.current) {
            isfuncCall.current = true;
            return new Promise((resolve, reject) => {
                try {
                    const url = `${URL_PATH}${GET_UPDATE}`;
                    let body = new FormData();

                    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
                    if (sessionStorage.getItem("last_id")) {
                        body.append(_LAST_ID_, sessionStorage.getItem("last_id"));
                    }
                    if (sessionStorage.getItem("last_id_actions")) {
                        body.append("last_id_actions", sessionStorage.getItem("last_id_actions"));
                    }
                    if (sessionStorage.getItem("last_update_time")) {
                        body.append("last_server_update", sessionStorage.getItem("last_update_time"));
                    }
                    if (props.isInRoom) {
                        body.append("items_to_get", "social,news,flow_objects,actions,game_info,docs");
                    } else {
                        body.append("items_to_get", "all");
                    }
                    const requestOptions = {
                        method: "POST",
                        body: body
                    };
                    fetch(url, requestOptions)
                        .then((res) => res.json())
                        .then((res) => {
                            isfuncCall.current = false;
                            sessionStorage.setItem("last_update_time", res.last_server_update);
                            for (let key in res) {
                                mapAndUpdateData(key, res, eventsArr);
                            }
                        }).catch((error) => { isfuncCall.current = false; reject(false) });
                } catch (e) { isfuncCall.current = false; }
            })
        }
    };

    const mapAndUpdateData = useCallback((key, res, eventsArr, socket) => {
        switch (key) {
            case "groups":
                if (socket) {
                    dispatch(
                        setChat(
                            { groups: [res.data] },
                            gameInfoPermission !== "1" ? sessionStorage.getItem(USER_ID_IN_GAME) : false,
                            [...gameInfo.players],
                        )
                    );
                    break;
                }
                if (res?.groups?.length > 0) {
                    dispatch(
                        setChat(
                            { groups: res.groups },
                            gameInfoPermission !== "1" ? sessionStorage.getItem(USER_ID_IN_GAME) : false,
                            [...gameInfo.players],
                        )
                    );
                }
                break;
            case "chat_messages":
                if (socket) {
                    sessionStorage.setItem("last_id", res?.date?.id);
                    dispatch(
                        setChat(
                            { messages: [res?.data] },
                            (gameInfoPermission !== "1" && gameInfoPermission !== "10") ? (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME)) : false,
                            [...gameInfo.players],
                        )
                    );
                    break;
                }
                if (res?.chat_messages?.length > 0) {
                    sessionStorage.setItem("last_id", res.chat_messages[res.chat_messages?.length - 1]?.id)
                    dispatch(
                        setChat(
                            { messages: res.chat_messages },
                            (gameInfoPermission !== "1" && gameInfoPermission !== "10") ? (sessionStorage.getItem(USER_ID_IN_GAME_OBSERVER) || sessionStorage.getItem(USER_ID_IN_GAME)) : false,
                            [...gameInfo.players],
                        )
                    );
                    break;
                }
                break;
            case "messages":
                if (socket) {
                    const playerId = sessionStorage.getItem(USER_ID_IN_GAME);
                    if (res?.data?.sender?.id === playerId) {
                        dispatch(setSentMail([res?.data], playerId, gameInfoPermission, lang));
                    } else {
                        dispatch(setMail([res?.data], playerId, gameInfoPermission, lang));
                    }
                    break;
                }
                if (res?.messages?.length > 0) {
                    const playerId = sessionStorage.getItem(USER_ID_IN_GAME);
                    const { mailMessages, systemMessages } = mapMails(
                        res.messages,
                        gameInfo?.players,
                        playerId,
                        gameInfoPermission
                    );
                    dispatch(setMail(mailMessages, playerId, gameInfoPermission, lang));
                    break;
                }
                break;
            case "social":
                if (res?.social?.length > 0) {
                    let is_admin = sessionStorage.getItem("create_game_permission") === "1" || gameInfoPermission === "1";
                    dispatch(setSocialMedia(res.social, sessionStorage.getItem(USER_ID_IN_GAME), gameInfo?.players, is_admin));
                }
                break;
            case "news":
                if (res?.news?.length > 0) {
                    dispatch(setNews(res.news));
                }
            case "flow_objects":
                if (res?.flow_objects?.length > 0) {
                    const { action, kpi } = mapActions(res?.flow_objects, eventsArr)
                    if (action?.length > 0) {
                        dispatch(setActionCreator(action));
                    }
                    if (kpi?.length > 0) {
                        dispatch(setKpi(kpi));
                    }
                }
                break;
            case "actions":
                if (res?.actions?.length > 0) {
                    sessionStorage.setItem("last_id_actions", res.actions[res?.actions?.length - 1].id);
                    dispatch(setAction(res.actions, gameInfoPermission, eventsArr));
                }
                break;
            case "docs":
                if (res?.docs?.length > 0) {
                    dispatch(setDocs(res.docs));
                    resolve(true)
                }
            case "game_info":
                if (Object.keys(res.game_info).length > 0) {
                    let inGame = (res.game_info?.start_time || res.game_info.end_time) ? checkStartGame(res) : true;
                    if (inGame) {
                        if (res.game_info.game_colors) {
                            setColors(res.game_info);
                        }
                        if (res.game_info?.game_logo) {
                            dispatch(addLogo(res.game_info.game_logo + '?timestamp=' + new Date().getTime()));
                        }
                        if (res.game_info?.players) {
                            let player = res.game_info?.players?.find(
                                (player) =>
                                    player.id === sessionStorage.getItem(USER_ID_IN_GAME)
                            );
                            dispatch(
                                setGamePermission(
                                    player?.permission_for_game === "10" ? "10" : sessionStorage.getItem(CREATE_GAME_PERMISSION) === "1" ? "1" :
                                        player?.permission_for_game
                                )
                            );
                            let current = res.game_info?.players?.find((e) => e.id === sessionStorage.getItem(USER_ID_IN_GAME));
                            dispatch(setUser({ ...JSON.parse(sessionStorage.getItem(USER))?.user, profile_image: current?.profile_image }));
                        }
                        dispatch(setGameInfo(res.game_info));
                        if (res.game_info.game_lang) {
                            dispatch(changeLangType(getLang(res.game_info.game_lang)));
                        }
                        if (res.game_info?.players) {
                            let { real, rep } = mapPlayers(res.game_info);
                            checkIfIsObserver(res?.game_info);
                            dispatch(setRealPlayers(real?.filter((e) => !e.observer_id)));
                            dispatch(setReplPlayers(rep));
                        }
                    }
                }
            // case "event": {
            //     if (res?.events?.length > 0) {
            //         dispatch(setEventsCreator(res?.events));
            //     }
            // }
        }
    }, [gameInfo.players]);

    useImperativeHandle(props?.updateProviderRef, () => ({ callToUpdateDataFromSocket }))
    const callToUpdateDataFromSocket = useCallback((key, data) => {
        mapAndUpdateData(key, data, eventsArr, true);
    }, [eventsArr]);

    useEffect(() => {
        setEvents(eventsArr);
    }, [eventsArr]);

    useEffect(() => {
        let myInterval;

        // פונקציה להפסקת האינטרוולים
        const clearMyIntervals = () => {
            clearInterval(myInterval);
        };
        if (sessionStorage.getItem(GAME_ID)) {
            // רענון נתונים לפי סוג הרענון
            if ([
                "social_media", "mail", "news", "swimlane",
                "chat", "actions", "game_info", "files"
            ].includes(props.typeRefresh)) {
                getUpdateData(events);
                clearMyIntervals();
                props.setTypeRefresh();
            }

            // רענון נתונים אם המשתמש לא בחדר
            if (props.isInRoom) {
                clearMyIntervals();
                // console.log("30");
                myInterval = setInterval(() => {
                    getUpdateData(events);
                }, 30000);
            }
            if (!props.isInRoom) {
                clearMyIntervals();
                // console.log("10");
                myInterval = setInterval(() => {
                    getUpdateData(events);
                }, 10000);
            }

            // רענון נתונים אם ה-websocket חסום
            if (props.webSocketBlocked) {
                clearMyIntervals();
                myInterval = setInterval(() => {
                    getUpdateData(events);
                }, 10000);
            }
        }
        return () => {
            clearMyIntervals();
        };
    }, [
        gameInfo?.game_name,
        props.loginType,
        props.typeRefresh,
        events,
        props.isInRoom,
        props.webSocketBlocked,
    ]);


    return <UpdateContext.Provider value={{
        getUpdateData,
    }}>
        {props.children}
    </UpdateContext.Provider>;
}
